import React from 'react'
import Layout from './src/components/Layout'
import { VolunteerProvider } from './src/components/VolunteerContext'

export function wrapPageElement({ element, props }) {
  return <Layout {...props}> {element}</Layout>
}

export function wrapRootElement({ element }) {
  return <VolunteerProvider>{element}</VolunteerProvider>
}
